import { useIsSSR } from "eddev/routing"
import { animate, motion, stagger, usePresence } from "framer-motion"
import { ComponentPropsWithoutRef, useLayoutEffect, useRef } from "react"

type Props = ComponentPropsWithoutRef<"div">

export function transitionProps() {
  return {
    "data-transition": "fade",
  }
}

export function Transition(props: Props) {
  return <motion.div {...(props as any)} data-transition="fade" />
}

export function StaggerTransitionRoot(props: Props & { duration?: number }) {
  const ref = useRef<HTMLDivElement>(null)
  const ssr = useIsSSR()
  const duration = props.duration ?? 0.1
  const [isPresent, safeToRemove] = usePresence()

  if (env.client) {
    useLayoutEffect(() => {
      if (!isPresent) {
        transitionOut(ref.current!).then(() => {
          safeToRemove()
        })
      } else {
        if (!ssr) {
          transitionIn(ref.current!)
        }
      }
    }, [isPresent])
  }

  return <motion.div ref={ref} variants={{ hide: {}, show: {} }} initial="hide" exit="hide" animate="show" {...(props as any)} duration={undefined} />
}

function getTransitionElements(el: Element) {
  const wh = window.innerHeight
  const ww = window.innerWidth
  const els: { el: HTMLElement; rect: DOMRect; mode: string }[] = []
  const addElement = (el: HTMLElement, mode: string) => {
    const rect = el.getBoundingClientRect()
    const onScreen = rect.top < wh && rect.bottom > 0 && rect.left < ww && rect.right > 0 && rect.height > 0 && rect.width > 0
    if (onScreen) {
      els.push({ el: el as HTMLElement, rect, mode })
    }
  }
  el.querySelectorAll("[data-transition]").forEach((el) => {
    let mode = el.getAttribute("data-transition") ?? "fade"
    if (mode.startsWith("children/")) {
      mode = mode.replace("children/", "")
      el.childNodes.forEach((el) => {
        if (el instanceof HTMLElement) {
          addElement(el, mode)
        }
      })
    } else {
      addElement(el as HTMLElement, mode)
    }
  })
  els.sort((a, b) => a.rect.top - b.rect.top || a.rect.left - b.rect.left)
  return els
}

const ELEMENT_FADE_IN_DURATION = 0.3
const ELEMENT_FADE_OUT_DURATION = 0.2
const FADE_IN_DURATION = 0.5
const FADE_OUT_DURATION = 0.3

function transitionIn(el: HTMLElement) {
  const els = getTransitionElements(el)
  if (els.length === 0) return Promise.resolve()
  els.forEach((el, i) => {
    el.el.style.setProperty("--factor", "0")
  })
  return animate(
    els.map((el, i) => el.el),
    { "--factor": 1 },
    { duration: ELEMENT_FADE_IN_DURATION, delay: stagger((FADE_IN_DURATION - ELEMENT_FADE_IN_DURATION) / els.length) }
  )
}

function transitionOut(el: HTMLElement) {
  const els = getTransitionElements(el)
  if (els.length === 0) return Promise.resolve()
  els.forEach((el, i) => {
    el.el.style.setProperty("--factor", el.el.style.getPropertyValue("--factor") || "1")
  })
  return animate(
    els.map((el, i) => el.el),
    { "--factor": 0 },
    {
      duration: ELEMENT_FADE_OUT_DURATION,
      delay: stagger((FADE_OUT_DURATION - ELEMENT_FADE_OUT_DURATION) / els.length),
    }
  )
}
